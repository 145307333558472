<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">Tag View</h4>
                <div>
                  <b-button variant="outline-red" squared @click="openDeleteModal" size="sm">Delete</b-button>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <div v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Name <span class="text-red">*</span></label>
                <b-form-input v-model="tagData.name" @blur="tagData.name.$touch()"></b-form-input>
              </b-col>
            </b-row>
            <b-row v-for="(group, mainIndex) in groupings" class="mb-3" :key="mainIndex">
              <b-col cols="12">
                <br />
                <h4 :style="{
                  textAlign: 'center',
                  background: getGradientColor(mainIndex, groupings.length),
                  color: '#fff',
                  padding: '5px 30px',
                  borderRadius: '3px 3px 0 0',
                }">
                  {{ group.name }}
                </h4>
                <br />
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox-group v-model="selectedLinks" id="checkbox-group-1">
                    <b-form-checkbox v-for="(item, index) in group.groupings" :key="index" :value="item">
                      {{ item.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>


            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="updateTag()" class="ml-2">Save</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="tagModal" hide-footer hide-header-close title="Delete Tag">

      <b-row>
        <b-col cols="12">
          <label class="text-center">Are you sure you want to delete <span
              class="font-weight-bold text-red text-center">{{ this.tagData.name }}</span>?</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="outline-red" @click="hideDeleteModal" squared class="mr-2">Cancel</b-button>
          <b-button variant="red" @click="removeTag" squared>Delete</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
  </div>
</template>
<style >
.primary {
  background-color: #ffcb14;
  color: #222222;
}

.primary:hover {
  color: #fff;
}
</style>
<script>
import { mapActions, mapMutations } from "vuex";
import api from "../../../api";

export default {
  name: "tagView",

  data: () => ({
    state: 'show',
    tagData: {
      id: 0,
      name: null,
      linksIds: [],
      roleTagIds: null,
    },
    links: [],
    reports: [],
    apps: [],
    groupings: [],
    selectedLinks: [],
    services: [],
    documents: [],
    documentTypes: []
  }),
  beforeMount() {
    this.setBreadcrumb([
      {
        text: 'Tag'
      },
      {
        text: 'Details'
      },
    ]);

    const ts = (data) => {
      this.links = data;
      function dynamicGrouping(data) {
        const mainObjects = {};
        const groupedData = [];

        data.forEach((x) => {
          const key = JSON.stringify(x.grouping);
          if (!mainObjects[key]) {
            mainObjects[key] = {
              name: x.grouping.name,
              groupings: [],
            };
            groupedData.push(mainObjects[key]);
          }
          mainObjects[key].groupings.push(x);
        });

        // Sort sub-objects within each main object by name ascending
        groupedData.forEach((mainObj) => {
          mainObj.groupings.sort((a, b) => a.name.localeCompare(b.name));
        });

        return groupedData;
      }

      this.groupings = dynamicGrouping(data);

      const s = (tagData) => {
        // Give up on life here
        this.tagData = tagData;
        var newSelected = [];

        tagData.linkIds.forEach((item) => {

          const result = this.links.find(({ id }) => id === item);
          newSelected.push(result)
        });
        this.selectedLinks = newSelected;
        this.state = 'show';
      };

      const e = (msg) => {
        console.log(msg);
      };
      this.tagData.id = localStorage.getItem("view_tag_id");

      api.getTagID(localStorage.getItem("view_tag_id"), s, e);

    };

    const et = (msg) => {
      console.log(msg);
    };
    api.getAllLinks(ts, et);
  },

  methods: {
    ...mapActions(["getTagDetail", "updateTag", "deleteTag"]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    getGradientColor(index, totalHeaders) {
      const startColor = [133, 132, 132]; // RGB value of #858484 (darker color)
      const endColor = [172, 172, 172]; // RGB value of #acacac (lighter color)

      const percent = index / (totalHeaders - 1);
      const color = startColor.map((start, i) =>
        Math.round(start + percent * (endColor[i] - start))
      );

      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    },

    updateTag() {
      const s = (data) => {
        this.$router.push({ path: '/admin/ManageTags/SearchTag' })
        console.log(data);
      };

      var linkIds = [];

      this.selectedLinks.forEach((item) => {
        const result = item.id;
        linkIds.push(result)
      });

      this.tagData.linkIds = linkIds;

      const e = (msg) => {
        console.log(msg);
      };

      api.UpdateTag(this.tagData, s, e)
    },

    goBackToSearch() {
      this.$router.push({ path: '/admin/ManageTags/SearchTag' })
    },

    removeTag() {
      var s = (data) => {
        console.log(data)
        this.hideDeleteModal();
        this.$router.push({ path: "/admin/ManageTags/SearchTag" })
      };
      var e = (error) => {
        this.hideDeleteModal();
        this.makeToast(error.message);
      };

      api.DeleteTag(this.tagData, s, e)
    },

    makeToast(message) {
      this.$bvToast.toast(`${message}`, {
        title: 'Something went wrong.',
        autoHideDelay: 5000,
        appendToast: false
      })
    },
    openDeleteModal() {
      this.$bvModal.show('tagModal')
    },

    hideDeleteModal() {
      this.$bvModal.hide('tagModal')
    },
  },
}
</script>

<style scoped></style>