var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('b-card',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h4',{staticClass:"m-0"},[_vm._v("Tag View")]),_c('div',[_c('b-button',{attrs:{"variant":"outline-red","squared":"","size":"sm"},on:{"click":_vm.openDeleteModal}},[_vm._v("Delete")])],1)])])],1),(_vm.state === 'loading')?_c('b-row',{staticClass:"h-100",attrs:{"align-h":"center"}},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e(),(_vm.state === 'show')?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v("Name "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('b-form-input',{on:{"blur":function($event){return _vm.tagData.name.$touch()}},model:{value:(_vm.tagData.name),callback:function ($$v) {_vm.$set(_vm.tagData, "name", $$v)},expression:"tagData.name"}})],1)],1),_vm._l((_vm.groupings),function(group,mainIndex){return _c('b-row',{key:mainIndex,staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12"}},[_c('br'),_c('h4',{style:({
                textAlign: 'center',
                background: _vm.getGradientColor(mainIndex, _vm.groupings.length),
                color: '#fff',
                padding: '5px 30px',
                borderRadius: '3px 3px 0 0',
              })},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('br')]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-1"},model:{value:(_vm.selectedLinks),callback:function ($$v) {_vm.selectedLinks=$$v},expression:"selectedLinks"}},_vm._l((group.groupings),function(item,index){return _c('b-form-checkbox',{key:index,attrs:{"value":item}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1)}),_c('hr',{staticClass:"mx-3"}),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-red","squared":""},on:{"click":_vm.goBackToSearch}},[_vm._v("Cancel")])],1),_c('div',[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","squared":""},on:{"click":function($event){return _vm.updateTag()}}},[_vm._v("Save")])],1)])])],1)],2):_vm._e()],1)],1)],1),_c('b-modal',{attrs:{"id":"tagModal","hide-footer":"","hide-header-close":"","title":"Delete Tag"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"text-center"},[_vm._v("Are you sure you want to delete "),_c('span',{staticClass:"font-weight-bold text-red text-center"},[_vm._v(_vm._s(this.tagData.name))]),_vm._v("?")])])],1),_c('b-row',[_c('b-col',{staticClass:"text-center mt-3",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-red","squared":""},on:{"click":_vm.hideDeleteModal}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"red","squared":""},on:{"click":_vm.removeTag}},[_vm._v("Delete")])],1)],1),_c('div',{staticClass:"d-block"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }